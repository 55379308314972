import React from 'react';

// let $ = window.jQuery;
// import 'slick-carousel';
import Slider from "react-slick";


const slickOptions = {
    autoplay: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 1,
    dots: true,
    focusOnSelect: true,
    autoplaySpeed: 5000,
    draggable: true,
    mobileFirst: true,
};

function BookACallCarousel(props) {
    const {
        block: {
            content: { bgImg, items },
        },
    } = props;

    const styles = {
        backgroundImage: `url(${bgImg})`,
    };


    return (
        <div className="BookACall-quotes" style={styles}>
            <div className="BookACall-quotes-wrap">
                {items && (
                    <Slider {...slickOptions} className="BookACall-carousel">
                        {items.map((i, key) => (
                            <li key={key}>
                                <h3 className="BookACall-quote-title">
                                    {i.quote}
                                </h3>
                                <p className="BookACall-quote-business">
                                    {i.customer} — {i.business}
                                </p>
                            </li>
                        ))}
                    </Slider>
                )}
            </div>
        </div>
    );
}

export default BookACallCarousel;
