import React, { useContext } from 'react';
import { PageContext } from '../context/page-context';

import { getRegionPriceInfo } from '../utils/pricing/calculator';
import { TimelyPlanName } from '../utils/pricing/TimelyPlanName';

export default function PricingDescription({ description, className }) {
  const { geolocation } = useContext(PageContext);

  const prices = getRegionPriceInfo(geolocation);

  const staffStart = prices.prices.staff[TimelyPlanName.BASIC] * 2;

  description = description.replace(
    '%solo',
    `${prices.sign}${prices.prices.solo[TimelyPlanName.BASIC]}`
  );
  description = description.replace('%staff', `${prices.sign}${staffStart}`);
  description = description.replace(
    '%fixed',
    `${prices.sign}${prices.prices.fixed[TimelyPlanName.ELEVATE]}`
  );

  return <div className={className} dangerouslySetInnerHTML={{ __html: description }} />;
}
