import React, { useEffect } from "react";
import { motion } from "framer-motion";

function CloseButton() {
  return (
    <svg
      width='43px'
      height='43px'
      viewBox='0 0 43 43'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='3'
          transform='translate(-1343.000000, -46.000000)'
          stroke='#FFFFFF'
          strokeWidth='3'
        >
          <path d='M1345.5,47.875 L1384.45117,86.8261719' id='Line'></path>
          <path
            d='M1345.5,47.875 L1384.45117,86.8261719'
            id='Line'
            transform='translate(1365.000000, 67.804688) rotate(-90.000000) translate(-1365.000000, -67.804688) '
          ></path>
        </g>
      </g>
    </svg>
  );
}

function Overlay({ show, lightModal, classes, children, handleClose, video }) {
  const closeOverlay = (e) => {
    e.preventDefault();
    if (handleClose) {
      handleClose();
    }
  };

  classes = lightModal
    ? `${classes} Overlay Overlay--light`
    : `${classes} Overlay`;

  const variants = {
    open: { opacity: 1, display: "block" },
    closed: { opacity: 0, display: "none" },
  };

  useEffect(() => {
    if (show) {
      document.querySelector("html").classList.add("Overlay-fixed");
      document.querySelector("body").classList.add("Overlay-on");
    } else {
      document.querySelector("html").classList.remove("Overlay-fixed");
      document.querySelector("body").classList.remove("Overlay-on");
    }
  }, [show]);

  useEffect(() => {
    function closeOnEscape(e) {
      if (e.key === 'Escape') {
        handleClose();
      }
    }

    document.addEventListener('keydown', closeOnEscape);

    return () => {
      document.removeEventListener('keydown', closeOnEscape);
    };
  }, [handleClose]);

  return (
    <motion.div
      data-testid='overlay'
      initial="closed"
      animate={show ? "open" : "closed"}
      transition={{ duration: 0.2 }}
      variants={variants}
      className={classes}
    >
      <button
        onClick={closeOverlay}
        data-testid='Overlay-close'
        className='Overlay-close'
      >
        <CloseButton />
      </button>
      {
        video &&
        <div className="VideoOverlay-wrap">{children}</div>
      }
      {
        !video &&
        <>{children}</>
      }
    </motion.div>
  );
}
export default Overlay;
