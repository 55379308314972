/**
 * Checks whether or not we have detected that the user is in a GDPR region.
*/
export function getIsGDPR(): boolean | null {
  if (global.document) {
    // Depending on which section of this site the user is in,
    // the GDPR indicator class may be on either the `html` or `body` element.
    const indicatorElements = document.querySelectorAll('html, body');

    // If any indicator element contains the GDPR indicator class,
    // we are in a GDPR region.
    const isGDPR = Array.from(indicatorElements).some((element) => element.classList.contains('gdpr'));

    return isGDPR;
  } else {
    // `document` won't exist during initial static build by NextJS, so we can't determine
    // if the user is in a GDPR region. Use `null` to represent this.
    return null;
  }
}

/**
 * This function was not documented, and its full
 * intended functionality is not clear. It uses brittle
 * selectors and appears to contain a number of bugs.
 *
 * @deprecated
*/
export function gdprSet() {
  // eslint-disable-next-line no-warning-comments
  // Todo: Make this work in tests
  const isGDPR = getIsGDPR();
  const hasPrivacy = document.querySelector("input[name=privacy]") !== null;
  if (isGDPR) {
    if (hasPrivacy) {
      const privacyElements = document.querySelectorAll(".Brochure-privacy");
      if (privacyElements !== null) {
        privacyElements.forEach((privacy) => {
          if (privacy.innerHTML.indexOf("and agree to receive") !== -1) {
            // @ts-expect-error This was broken when I found it. Not sure how to test for regressions if I fix it
            privacy.innerHTML.replace(
              ", and agree to receive Timely news and marketing offers."
            );
          }
        });
      }

      const agreeToReceiveElements = document.querySelector<HTMLElement>(".col-lg-12.privacy-inline .u-midGrey.mt0");
      if (
        agreeToReceiveElements !== null &&
        agreeToReceiveElements.innerText.indexOf("and agree to receive") !== -1
      ) {
        agreeToReceiveElements.innerHTML
          .replace(", and agree to receive Timely news and marketing offers.", "");
      }
    }
    return;
  }


  if (hasPrivacy) {
    const privacyElements = document.querySelectorAll("input[name=privacy]");
    privacyElements.forEach((privacy) =>
      privacy.setAttribute("checked", String(true))
    );

    const newsOffersElement = document.querySelector("input[name=newsoffers]");
    if (newsOffersElement !== null) {
      newsOffersElement.setAttribute("checked", String(true));
    }

    const brochurePrivacyElements = document.querySelectorAll(".Brochure-privacy");
    brochurePrivacyElements.forEach((privacy) => {
      if (privacy.innerHTML.indexOf("and agree to receive") === -1) {
        privacy.append(
          ", and agree to receive Timely news and marketing offers."
        );
      }
    });

    const modalNewsOffersElement = document.querySelector(".formmodal-1-newsoffers");
    if (modalNewsOffersElement !== null) {
      // @ts-expect-error This was broken when I found it. Not sure how to test for regressions if I fix it
      modalNewsOffersElement.setAttribute({
        display: 'none',
      });
    }

    const agreeToReceiveElement = document.querySelector<HTMLElement>(".col-lg-12.privacy-inline .u-midGrey.mt0");
    if (
      agreeToReceiveElement !== null &&
      agreeToReceiveElement.innerText.indexOf("and agree to receive") === -1
    ) {
      agreeToReceiveElement.append(", and agree to receive Timely news and marketing offers.");
    }
  }
}

declare global {
  interface Window {
    gdprSet: typeof gdprSet;
  }
}

if (typeof window !== "undefined") {
  window.gdprSet = gdprSet;
}
