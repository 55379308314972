import type { MarketoForm } from './marketo';

import { MARKETO_BASE_URL, MARKETO_MUNCHKIN_ID } from '../../../lib/constants';

import { getFormElById } from './getFormElById';

/** A collection of unresolved Promises to use if a form is told to load again before it's done loading */
const pendingForms: WeakMap<HTMLElement, Promise<MarketoForm>> = new WeakMap();

/** A collection of loaded MarketoForms to use if a form is told to load again after it's already loaded */
const loadedForms: WeakMap<HTMLElement, MarketoForm> = new WeakMap();

const loadedFormIds: string[] = [];

/**
 * Loads a form descriptor from Marketo servers and creates a new Form object.
 *
 * This function has Timely's Marketo base URL and Munchkin ID baked in, and exposes a `Promise` interface instead of expecting a callback as an argument.
 *
 * @param {string | number} formId - The form version id (Vid) of the form to load
 *
 * @return {Promise<MarketoForm>} A `Promise` that resolves to the `MarketoForm` that is created.
 */
export function loadForm(formId: string | number): Promise<MarketoForm> {
    const formIdStr = String(formId);
    const formEl = getFormElById(formId);

    let formLoadPromise = formEl && pendingForms.get(formEl);
    if (formLoadPromise) {
        // If the form is currently in the process of loading, return the pending `Promise`
        return formLoadPromise;
    } else {
        // The form is either not yet loading, or already loaded. Either way, return a new `Promise`
        formLoadPromise = new Promise((resolve, reject) => {
            const { MktoForms2 } = window;
            if (!MktoForms2) {
                reject(new Error('The Marketo API is not yet available'));
                return;
            }

            if (!formEl) {
                reject(new Error(`No element exists for Marketo form ${formId}`));
                return;
            }

            // If a form's loading has already been kicked off, don't try to load it again
            const loadedForm = loadedForms.get(formEl);
            if (loadedForm) {
                // The MarketoForm has already loaded, so resolve immediately
                resolve(loadedForm);
            } else {
                // The MarketoForm hasn't been loaded yet, so start loading it
                MktoForms2.loadForm(MARKETO_BASE_URL, MARKETO_MUNCHKIN_ID, formIdStr, (form) => {
                    // Remove the pending `Promise`, and record the loaded `MarketoForm`
                    pendingForms.delete(formEl);
                    loadedForms.set(formEl, form);
                    loadedFormIds.push(String(formId));

                    resolve(form);

                    // Only one Marketo form should load per page.
                    // So if there are now two forms loaded, then
                    // instead of just returning at the end, after
                    // resolving, also throw an error for reporting.
                    if (loadedFormIds.length > 1) {
                        throw new Error(`Loaded multiple Marketo forms on the same page\nURL: ${document.location.href}\nForm IDs: ${loadedFormIds.join(', ')}`);
                    }
                });
            }
        });

        let alreadyLoaded = false;
        formLoadPromise.then(() => alreadyLoaded = true);

        if (formEl && alreadyLoaded === false) {
            // Keep track of the pending `Promise` while the form is loaded, in case it's told to load again
            pendingForms.set(formEl, formLoadPromise);

            // If `formEl` is falsy, the `Promise` will immediately reject.
            // If `alreadyLoaded` is true, the `Promise` has already immediately resolved.
            // In either case, we don't need to track it while pending anyway
        }
        return formLoadPromise;
    }
}
