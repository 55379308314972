import React, { Component } from 'react';
import OnOutsiceClick from 'react-outclick';
import Overlay from './overlay';
import BookACall from './book-a-call';
import ConsultSendForm from './consult-send-form';

import { PageContext } from '../context/page-context';

class BookACallModal extends Component {
    state = {
        open: false,
    };
    outSideClick = event => {
        for (
            let currentItem = event.target;
            currentItem != event.currentTarget && currentItem != null;
            currentItem = currentItem.parentElement
        ) {
            if (currentItem.classList.contains('btn-nav-call')) {
                event.preventDefault();
                // or currentItem.classList.contains("item")
                // or currentItem.matches(".item")
                this.setState({ open: true });
                return false;
            }
            if (
                currentItem.classList.contains('consult-modal') ||
                currentItem.classList.contains('Button--consult-modal')
            ) {
                event.preventDefault();
                // or currentItem.classList.contains("item")
                // or currentItem.matches(".item")
                this.setState({ openConsult: true });
                return false;
            }
        }
    };

    hideModal = () => {
        this.setState({
            open: false,
            openConsult: false,
        });
    };

    render() {
        const { open, openConsult } = this.state;
        return (
            <>
                <OnOutsiceClick onOutsideClick={this.outSideClick}>
                    <Overlay
                        show={open || openConsult}
                        classes="Overlay--white Overlay--left form-labels-dark"
                        handleClose={this.hideModal}>
                        {open && (
                            <BookACall
                                block={this.props.block}
                                context={this.context}
                            />
                        )}
                        {openConsult && <ConsultSendForm />}
                    </Overlay>
                </OnOutsiceClick>
            </>
        );
    }
}

BookACallModal.contextType = PageContext;

export default BookACallModal;
