import React from 'react';

import PropTypes from 'prop-types';
import type { InferProps } from 'prop-types';

import Head from 'next/head';

const propTypes = {
	post: PropTypes.shape({
		id: PropTypes.number.isRequired,
	}).isRequired,
};
type PreviewBarProps = InferProps<typeof propTypes>;

const PreviewBar = Object.assign(
	function PreviewBar({ post }: PreviewBarProps) {
		const url = process.env.NEXT_PUBLIC_ROOTURL;
		return (
			<div>
				<Head>
					<link
						rel="stylesheet"
						id="admin-bar-css"
						href="https://www.gettimely.com/wp-includes/css/admin-bar.min.css?ver=5.8.2"
						type="text/css"
						media="all"
					/>
				</Head>
				<div id="wpadminbar" className="nojq">
					<div
						className="quicklinks"
						id="wp-toolbar"
						role="navigation"
						aria-label="Toolbar">
						<ul id="wp-admin-bar-root-default" className="ab-top-menu">
							<li id="wp-admin-bar-site-name" className="menupop">
								<a href={`${url}wp-admin/`}>Timely Dashboard</a>
							</li>
							<li id="wp-admin-bar-edit">
								<a
									href={`${url}wp-admin/post.php?post=${post.id}&action=edit`}>
									Edit Page
								</a>
							</li>
							<ul
								id="wp-admin-bar-top-secondary"
								className="ab-top-secondary ab-top-menu">
								<li>
									<a
										className="ab-item"
										href={`/api/exit-preview`}>
										Logout
									</a>
								</li>
								<li>You are in preview mode</li>
							</ul>
							{/* <li id="wp-admin-bar-userSegments" className="menupop">
								<a
									className="ab-item"
									aria-haspopup="true"
									href="#">
									User Segments
								</a>
								<div className="ab-sub-wrapper">
									<ul
										id="wp-admin-bar-userSegments-default"
										className="ab-submenu">
										<li id="wp-admin-bar-business-status-active">
											<a
												className="ab-item"
												href="?Array=Array&amp;">
												Business Status: Active
											</a>
										</li>
										<li id="wp-admin-bar-business-status-trialling-and-trialended">
											<a
												className="ab-item"
												href="?Array=Array&amp;">
												Business Status: Trialling,
												TrialEnded and Test
											</a>
										</li>
									</ul>
								</div>
							</li> */}
						</ul>
					</div>
				</div>
			</div>
		);
	},

	{ propTypes }
);

export default PreviewBar;
