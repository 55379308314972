import React from "react";
import PropTypes from "prop-types";
import cookieManager from "../utils/cookie";
import imagePropType from "../prop-types/image";
import actionPropType from "../prop-types/action";

class NotificationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideNotification: false,
    };
  }

  componentDidMount() {
    const {
      block: {
        config: { isClosed },
      },
    } = this.props;

    let hideNotification = cookieManager.get("hideNotification") || false;

    hideNotification = hideNotification === "true";

    if (isClosed) {
      hideNotification = true;
    }

    this.setState({ hideNotification });

    if (
      !hideNotification &&
      document &&
      document.getElementById("NavWrapper")
    ) {
      document
        .getElementById("NavWrapper")
        .classList.add("navbar--hasNotification");

    }
  }

  closeNotificationBar() {
    cookieManager.set("hideNotification", true, 1);
    this.setState({ hideNotification: true });
    if (
      document &&
      document.getElementById("NavWrapper")
    ) {
      document
        .getElementById("NavWrapper")
        .classList.remove("navbar--hasNotification");

    }
  }

  render() {
    const {
      block: { content, config },
    } = this.props;

    const { hideNotification } = this.state;

    const classNames =
      config && config.classNames
        ? `${config.classNames} NotificationBar`
        : "NotificationBar";
    const styles = {
      backgroundImage: `url(${content.backgroundImage})`,
    };

    if (hideNotification) {
      styles.height = "0";
    }

    const buttonClass = content.action
      ? `NotificationBar-button Button Button--${content.action.type} ${content.action.style}`
      : "";

    return (
      <div className={classNames} style={styles}>
        <div>&nbsp;</div>
        <div className='NotificationBar-container'>
          {content.image && content.image.src && (
            <div className='NotificationBar-title'>
              <img src={content.image.src} alt={content.image.alt} />
            </div>
          )}
          <p
            className='NotificationBar-description'
            dangerouslySetInnerHTML={{
              __html: content.description,
            }}
          />
          {content.action && (
            <a
              data-category='engagement'
              data-action='click link'
              data-label='notification bar'
              className={buttonClass}
              href={content.action.url}
            >
              {content.action.label}
            </a>
          )}
        </div>
        <button
          className='NotificationBar-close'
          onClick={() => {
            this.closeNotificationBar();
          }}
        >
          <svg
            width='43px'
            height='43px'
            viewBox='0 0 43 43'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
          >
            <g
              id='Page-1'
              stroke='none'
              strokeWidth='1'
              fill='none'
              fillRule='evenodd'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <g
                id='3'
                transform='translate(-1343.000000, -46.000000)'
                stroke='#FFFFFF'
                strokeWidth='3'
              >
                <path
                  d='M1345.5,47.875 L1384.45117,86.8261719'
                  id='Line'
                ></path>
                <path
                  d='M1345.5,47.875 L1384.45117,86.8261719'
                  id='Line'
                  transform='translate(1365.000000, 67.804688) rotate(-90.000000) translate(-1365.000000, -67.804688) '
                ></path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    );
  }
}

NotificationBar.propTypes = {
  block: PropTypes.shape({
    content: PropTypes.shape({
      image: imagePropType,
      action: actionPropType,
    }),
  }),
};

export default NotificationBar;
