import { Region } from '../Region';

export const SmsPricingRegion = {
	AUSTRALIA: Region.AUSTRALIA,
	CANADA: Region.CANADA,
	GREAT_BRITAIN: Region.GREAT_BRITAIN,
	IRELAND: Region.IRELAND,
	INDIA: Region.INDIA,
	NETHERLANDS: Region.NETHERLANDS,
	NORWAY: Region.NORWAY,
	NEW_ZEALAND: Region.NEW_ZEALAND,
	UNITED_STATES: Region.UNITED_STATES,
	REST_OF_THE_WORLD: Region.REST_OF_THE_WORLD,
} as const;
export type SmsPricingRegion = typeof SmsPricingRegion[keyof typeof SmsPricingRegion];
