import React from 'react';
import Link from 'next/link';
import isInternalLink from '../../utils/is-internal-link';

import PropTypes, { InferProps } from 'prop-types';

const propTypes = {
	className: PropTypes.string,
	href: PropTypes.string.isRequired,
	from: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
};
type LinkNavPropTypes = InferProps<typeof propTypes>;

const defaultProps = {
	className: undefined,
	children: null,
} as const;

const LinkNav = Object.assign(
	function LinkNav(props: LinkNavPropTypes) {
		const className = props.className ?? defaultProps.className;
		const children = props.children ?? defaultProps.children;
		const { href } = props;

		// if (href.indexOf('/') == 0) {
		if (
			isInternalLink(href)
		) {
			if (href === '/') {
				return <Link
					href={href}
				>
					<a
						aria-label="Timely Home"
						className={className}
					>
						{children}
					</a>
				</Link>;
			}

			return <Link
				href={href}
			>
				<a
					className={className}
				>
					{children}
				</a>
			</Link>;
		}
		return (
			<a
				href={href}
				className={className}
			>
				{children}
			</a>
		);
	},

	{
		propTypes,
		defaultProps,
	}
);

export default LinkNav;
