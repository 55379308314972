import { EnumTypeOf } from 'components/utils/types/EnumTypeOf';
import PropTypes from 'prop-types';
import getEnumValidator from './enum';

export const ActionBehaviour = {
	STANDARD: 'standard',
	CTA_FREE_TRIAL: 'cta-free-trial',
	MODAL_BOOK_A_CALL: 'modal-book-a-call',
	MODAL_GATED_CONTENT: 'modal-gated-content',
	CONSULT_MODAL: 'consult-modal',
	DISABLED: 'disabled',
} as const;
export type ActionBehaviour = EnumTypeOf<typeof ActionBehaviour>;

const actionProps = PropTypes.shape({
	url: PropTypes.string.isRequired,
	label: PropTypes.string,
	type: PropTypes.string,

	classes: PropTypes.arrayOf(PropTypes.string.isRequired),
	style: PropTypes.string,
	icon: PropTypes.string,

	behaviour: getEnumValidator(ActionBehaviour),
}).isRequired;

export default actionProps;
