const industryPages = [
    '/hair-salon-software',
    '/barber-software',
    '/tattoo-studio-software',
    '/spa-software',
    '/massage-therapist-software',
    '/wellness-software',
    '/medi-aesthetics-software',
    '/beauty-software',
];
export default function isInternalLink(href: string): boolean {
    return (
        href === '/gb' ||
        href === '/' ||
        industryPages.includes(`${href}`) ||
        href.indexOf('/features') === 0 ||
        href.indexOf('/pricing') === 0
    );
}
