import React from 'react';

import Head from "next/head";

export default function Header({ title, meta, jsonld }) {
  return (
    <Head>
      <title>{title || "Timely Salon Software"}</title>
      {meta &&
        meta.map((value) =>
          value.name ? (
            <meta key={value.name} name={value.name} content={value.content} />
          ) : (
            <meta
              key={value.property}
              property={value.property}
              content={value.content}
            />
          )
        )}

      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta
        name='google-site-verification'
        content='UqNb4zVSy9XKMfF7szQfIicIoKXY9Urnj9D4cbfqvXA'
      />
      <meta name="facebook-domain-verification" content="jt0owwodfbkeus5hxoewyvimqbm6js" />
      <meta property='fb:app_id' content='1059867380836388' />

      <script src="//go.gettimely.com/js/forms2/js/forms2.min.js"></script>
      <link rel="manifest" href="/wp-content/themes/timely-timber/manifest.json" />
      <link rel="icon" type="image/x-icon" href="https://www.gettimely.com/wp-content/themes/timely-timber/assets/images/favicon.ico" />

      <script
        dangerouslySetInnerHTML={{
          __html: `var timber_ajax_obj = {"ajaxurl":"${process.env.NEXT_PUBLIC_ROOTURL}/wp-admin/admin-ajax.php"};`
        }}
      />

      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonld) }}
      />
      <script
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: `   (function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:877251,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        }}
      />

      <script
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: `  (function (o) {
    var b = "https://nimbleswan.io/anywhere/",
      t = "878ff2bdb3f0438cb07d7add7ffca8b65d12a44437ec4dcaa68484ac1eee7406",
      a = (window.AutopilotAnywhere = {
        _runQueue: [],
        run: function () {
          this._runQueue.push(arguments);
        },
      }),
      c = encodeURIComponent,
      s = "SCRIPT",
      d = document,
      l = d.getElementsByTagName(s)[0],
      p =
        "t=" +
        c(d.title || "") +
        "&u=" +
        c(d.location.href || "") +
        "&r=" +
        c(d.referrer || ""),
      j = "text/javascript",
      z,
      y;
    if (!window.Autopilot) window.Autopilot = a;
    if (o.app) p = "devmode=true&" + p;
    z = function (src, asy) {
      var e = d.createElement(s);
      e.src = src;
      e.type = j;
      e.async = asy;
      // l wasn't working so I have to replace l with this:
      d.getElementsByTagName("SCRIPT")[0].parentNode.insertBefore(
        e,
        d.getElementsByTagName("SCRIPT")[0]
      );
    };
    y = function () {
      z(b + t + "?" + p, true);
    };
    if (window.attachEvent) {
      window.attachEvent("onload", y);
    } else {
      window.addEventListener("load", y, false);
    }
  })({});`,
        }}
      />
    </Head>
  );
}
