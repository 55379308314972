import React from 'react';
import { MDCSelect } from '@material/select';
import { MDCTextField } from '@material/textfield';
import { gdprSet } from '../utils/gdpr';

import axios from 'axios';
import countries from '../utils/country-list';

import { PageContext } from '../context/page-context';
import { marketoSetCountries } from '../utils/marketo-set-countries';
import BookACallCarousel from './book-a-call/carousel';

import * as Marketo from '../utils/marketo/index';

const ID = 1058;

class ConsultSendForm extends React.Component {
    fields = [];

    constructor(props, context) {
        super(props);
        this.state = {
            form: {
                fname: context && context.fname ? context.fname : '',
                lname: context && context.lname ? context.lname : '',
                email: context && context.email ? context.email : '',
                message: '',
                LatestMainSource: 'Contact Request',
                LatestSubSource: 'Contact Request',
                newsoffers: false,
            },
            submitLabel: 'Text me my form',
            isDisabled: false,
            formSubmitted: false,
            open: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePhoneInputChange = this.handlePhoneInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    UNSAFE_componentWillMount() {
        setTimeout(() => {
            const textFieldElements = [].slice.call(
                document.querySelectorAll('.mdc-text-field')
            );

            gdprSet();

            textFieldElements.forEach(textFieldEl => {
                let input = textFieldEl.querySelectorAll('input');

                if (!input.length > 0) {
                    input = textFieldEl.querySelectorAll('textarea');
                }

                if (input.length > 0) {
                    this.fields[input[0].name] = new MDCTextField(textFieldEl);
                }
            });
            const selectElements = [].slice.call(
                document.querySelectorAll('.mdc-select')
            );

            selectElements.forEach(selectEl => {
                // new MDCSelect(selectEl);
                const input = selectEl.querySelectorAll('select');

                if (input[0]) {
                    this.fields[input[0].name] = new MDCSelect(selectEl);
                }
            });
        }, 250);

        const { MktoForms2 } = window;
        if (typeof MktoForms2 !== 'undefined') {
            MktoForms2.loadForm("//go.gettimely.com", "304-RPU-875", ID, (form) => {
                marketoSetCountries(this.context);
                Marketo.addCustomValidationRules();
            });
        }
    }

    componentDidUpdate() {
        const textFieldElements = [].slice.call(
            document.querySelectorAll('.mdc-text-field')
        );

        gdprSet();

        textFieldElements.forEach(textFieldEl => {
            let input = textFieldEl.querySelectorAll('input');

            if (!input.length > 0) {
                input = textFieldEl.querySelectorAll('textarea');
            }

            if (input.length > 0) {
                this.fields[input[0].name] = new MDCTextField(textFieldEl);
            }
        });

        const selectElements = [].slice.call(
            document.querySelectorAll('.mdc-select')
        );

        selectElements.forEach(selectEl => {
            // new MDCSelect(selectEl);
            const input = selectEl.querySelectorAll('select');

            if (input[0]) {
                this.fields[input[0].name] = new MDCSelect(selectEl);
            }
        });

        window.fields = this.fields;
    }
    resetForm(e) {
        e.preventDefault();
        this.setState({
            submitLabel: 'Text me my form',
            isDisabled: false,
            formSubmitted: false,
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { form } = this.state;

        const newForm = Object.assign({}, form, { [name]: value });

        this.setState({
            form: newForm,
        });
    }
    handlePhoneInputChange(event) {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { form } = this.state;

        if (!value.match(/^\+[1-9][0-9 \-().]{7,}/) === null) {
            const title = "Phone number should include  +country code (+64-20-100-2000)";
            event.target.setCustomValidity(title);
        }

        const newForm = Object.assign({}, form, { [name]: value });

        this.setState({
            form: newForm,
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        const { form } = this.state;

        const { geolocation } = this.context;
        const form_data = new FormData();
        let sessionId = '';
        let newsletter = 'yes';
        let MailingCountry = 'United Kingdom';

        this.setState({
            submitLabel: 'Sending you an SMS',
            isDisabled: true,
        });

        const { AutopilotAnywhere } = window;
        if (AutopilotAnywhere && AutopilotAnywhere.sessionId) {
            sessionId = AutopilotAnywhere.sessionId;
        }

        if (typeof countries[geolocation] !== 'undefined') {
            MailingCountry = countries[geolocation];
        }
        if (document.querySelector('html').classList.contains('gdpr')) {
            newsletter = form.newsoffers == true ? 'yes' : 'no';
        }

        const IsTimelyUser = form['timely-user'] === 'yes' ? true : false;

        let source = 'ConsultSMS';
        if (typeof window !== 'undefined') {
            source = window.location.pathname;
        }

        const Mobile = form.tel.replace(' ', '').replace('-', '');

        form_data.append('action', 'submit_to_autopilot');
        form_data.append(
            'data',
            JSON.stringify({
                _autopilot_session_id: sessionId,
                contact: {
                    Email: form.email,
                    FirstName: form.fname,
                    LastName: form.lname,
                    Mobile,
                    MailingCountry,
                    custom: {
                        'string--Source_LatestMainSource': 'Content',
                        'string--Source_LatestSubSource': 'Consultation App',
                        'string--Source_LatestSourceDetail': source,
                        'string--FormField_Privacy': 'yes',
                        'string--FormField_Newsletter': newsletter,
                        'boolean--IsTimelyUser': IsTimelyUser,
                    },
                },
            })
        );

        this.submitForm(form_data);
        return form_data;
    }

    submitForm(form) {
        const { timber_ajax_obj } = window;
        if (typeof timber_ajax_obj !== 'undefined') {
            return axios.post(timber_ajax_obj.ajaxurl, form).then(res => {
                this.setState({
                    formSubmitted: true,
                });
            });
        }
    }

    renderTextField(label, id) {
        const { isDisabled } = this.state;

        return (
            <div>
                <div className="mdc-text-field mdc-text-field--fullWidth mdc-text-field--outlined">
                    <input
                        className="mdc-text-field__input"
                        data-testid={id}
                        disabled={isDisabled}
                        defaultValue={this.context[id] || ''}
                        name={id}
                        onChange={this.handleInputChange}
                        required={true}
                    />
                    <div className="mdc-notched-outline">
                        <div className="mdc-notched-outline__leading"></div>
                        <div className="mdc-notched-outline__notch">
                            <label className="mdc-floating-label">
                                {label}
                            </label>
                        </div>
                        <div className="mdc-notched-outline__trailing"></div>
                    </div>
                </div>
                <div className="mdc-text-field-helper-line">
                    <p className="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
                        This field is required
                    </p>
                </div>
            </div>
        );
    }

    renderForm() {
        // const { isDisabled, submitLabel } = this.state;
        return (
            <>
                <div className="u-textCenter">
                    <h2 className="BookACall-mt-3">Try our jazzy online forms</h2>
                    <p>
                        Keen to see what all the fuss is about? Just enter your details below and we’ll send you an SMS message with a link to our super slick demo form!
                    </p>
                </div>
                <form id={`mktoForm_${ID}`}></form>
            </>
        );
    }

    hideModal = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { formSubmitted, form } = this.state;
        const IsTimelyUser = form['timely-user'] === 'yes' ? true : false;
        const carousel = {
            content: {
                bgImg: 'https://www.gettimely.com/wp-content/uploads/2020/11/Screen-Shot-2020-11-25-at-1.57.01-PM.jpg',
                items: [
                    {
                        quote:
                            '\u201cOmg we are loving Timely Consult! Having the ability to create our own consultation forms has been so great.\u201d',
                        customer: 'MEGAN PANOZZO,',
                        business: 'IN AWE SALON',
                    }
                ],
            },
        };
        return (
            <div className="BookACall-wrap">
                <div className="BookACall-form">
                    <div className="BookACall-form-container u-textCenter">
                        {!formSubmitted && this.renderForm()}
                        {formSubmitted && !IsTimelyUser && (
                            <>
                                <h3 className="AskQuestion-title u-vSpacingMD-top">
                                    Form sent!
                                </h3>
                                <p>
                                    We hope you like the Timely Consult experience. There are lots more game-changing features in the Timely toolbox for your business, and you can try them all free for 14 days!
                                </p>
                                <p>
                                    <a href="https://app.gettimely.com/register" className="Button Button--red">Start a free trial</a>
                                </p>
                            </>
                        )}
                        {formSubmitted && IsTimelyUser && (
                            <>
                                <h3 className="AskQuestion-title u-vSpacingMD-top">
                                    Form sent!
                                </h3>
                                <p>
                                    We hope you like the Timely Consult experience. Now is the perfect time to create your own snazzy online form! You can use our expert templates, or create your own using our easy form builder.
                                </p>
                                <p>
                                    <a href="https://app.gettimely.com/Setup/Consult/?firstlaunch=true" className="Button Button--red">Let’s make form magic</a>
                                </p>
                            </>
                        )}
                    </div>
                </div>
                <BookACallCarousel block={carousel} />
            </div>
        );
    }
}

ConsultSendForm.contextType = PageContext;

export default ConsultSendForm;
