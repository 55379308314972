import PropTypes from 'prop-types';

/**
 * Create a `PropTypes.Requireable` function for a validating that a prop is a member of a given string enum.
 *
 * @param {Record<string, E>} stringEnum - The string `enum` to validate against.
 *
 * @returns {PropTypes.Requireable} Use the `isRequired` property if the prop should non-nullable.
 */
function getEnumValidator<E extends string>(stringEnum: Record<string, E>): PropTypes.Requireable<E> {
	const enumValidator: PropTypes.Validator<E | null | undefined> = function enumValidator(props, propName, componentName) {
		const validValues = Object.values(stringEnum);
		const propValue = props[propName] as unknown;

		if (propValue === null || typeof propValue === 'undefined') {
			return null;
		} else if ((validValues as unknown[]).includes(propValue)) {
			return null;
		} else {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}: ${JSON.stringify(props[propName])}`);
		}
	};

	const requiredValidator: PropTypes.Validator<NonNullable<E>> = function enumValidatorRequired(props, propName, componentName, ...args) {
		const propValue = props[propName] as unknown;

		if (propValue === null || typeof propValue === 'undefined') {
			return new Error(`Invalid prop ${propName} supplied to ${componentName}: ${JSON.stringify(props[propName])}`);
		}

		return enumValidator(props, propName, componentName, ...args);
	};

	return Object.assign(enumValidator, { isRequired: requiredValidator });
}

export default getEnumValidator;
