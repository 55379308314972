import type { MarketoForm } from './marketo';

import countries from '../country-list';

const enum Selector {
    COUNTRY_INPUT = '[name="Country"]',
    MARKETO_ROW = '.mktoFormRow',
}

type UserContext = {
    // This property should always be present as a string, but must be declared this way due to how the 'prop-types' library works
    geolocation?: string | null,
};

/**
 * Automatically fill the Country field if it's present and we know the value it should have, and then hide it.
 *
 * This function creates a function, and is intended for use to create a callback with access to a user context within its closure. If it needs to be called directly, for example if it's called within part of a larger `loadForm` callback, it will need to be called as `autofill(context)(form);`
 *
 * Old Marketo forms may still use `components/util/marketo-set-countries`
 *
 * @param {UserContext | null | undefined} context - The user context object containing values that can be used to prefill fields.
 *
 * @return {(form: MarketoForm): void} A callback that can be used with the `loadForm` method.
 */
 export function autofill(context?: UserContext | null) {
    // Not sure why TypeScript is failing to correctly infer this type, but this type assertion should be safe
    const countryValue = countries[context?.geolocation ?? ''] as string | undefined;

    return function (form: MarketoForm): void {
        if (typeof countryValue !== 'undefined') {
            const formEl = form.getFormElem()?.[0];

            if (formEl) {
                const countryFields = formEl.querySelectorAll<HTMLSelectElement | HTMLInputElement>(Selector.COUNTRY_INPUT);
                countryFields.forEach((field) => {
                    field.value = countryValue;

                    const row = field.closest<HTMLElement>(Selector.MARKETO_ROW);
                    if (row) {
                        row.hidden = true;

                        // Setting the hidden attribute alone isn't enough because `!important` has been massively overused in `_gated-form.scss`,
                        // so the display rule can't be overridden without making the element no longer match the selector overriding it.
                        row.classList.remove(Selector.MARKETO_ROW.substring(1));
                    }
                });
            }
        }
    };
}
