import React from "react";
import cookieManager from "../utils/cookie";
import { PageContext } from "../context/page-context";

const eu = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
  "GB",
];

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);
    this.acceptPolicy = this.acceptPolicy.bind(this);

    this.state = {
      hideBanner: true,
    };
  }

  acceptPolicy(e) {
    // Set cookie for 20 years
    cookieManager.set("cookiePolicy", true, 7300);

    this.setState({
      hideBanner: true,
    });
  }

  componentDidUpdate() {
    let hideBanner = true;
    const { geolocation } = this.context;
    // const detectedCountry = cookieManager.get(Cookie.TIMELY_AC) || "Unknown";

    if (eu.indexOf(geolocation) >= 0) {
      hideBanner = false;
    }

    if (geolocation == "") {
      hideBanner = false;
    }

    const cookiePolicy = cookieManager.get("cookiePolicy");
    if (cookiePolicy) {
      hideBanner = true;
    }

    if (this.state.hideBanner != hideBanner) {
      this.setState({
        hideBanner,
      });
    }
  }

  render() {

    const { hideBanner } = this.state;


    if (hideBanner) {
      return null;
    }

    return (
      <div className='CookieConsent'>
        <div className='container'>
          <div className='CookieConsent-description'>
            We use cookies to make your website experience better. By continuing
            to use our website, you agree to this.
            <div className='CookieConsent-link'>
              <a href='/support/cookies/'>
                Learn more{" "}
                <span className='u-sm-hidden'>
                  or see how to adjust your cookie settings
                </span>
              </a>
            </div>
          </div>
          <div className='CookieConsent-button'>
            <button aria-label="Accept Cookie Concent" onClick={this.acceptPolicy}>
              <svg
                viewBox='0 0 14 14'
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
              >
                <g
                  id='Page-1'
                  stroke='none'
                  strokeWidth='1'
                  fill='none'
                  fillRule='evenodd'
                >
                  <g
                    id='Desktop-Copy'
                    transform='translate(-1402.000000, -1049.000000)'
                    fill='#FFFFFF'
                    fillRule='nonzero'
                  >
                    <g
                      id='x-gon-give-it-to-ya'
                      transform='translate(1408.946232, 1056.000900) rotate(-315.000000) translate(-1408.946232, -1056.000900) translate(1400.446232, 1046.500900)'
                    >
                      <path
                        d='M7.48614603,1.19012571 L7.48614603,17.3399409 C7.48614603,17.8922257 7.93386128,18.3399409 8.48614603,18.3399409 C9.03843078,18.3399409 9.48614603,17.8922257 9.48614603,17.3399409 L9.48614603,1.19012571 C9.48614603,0.637840957 9.03843078,0.190125706 8.48614603,0.190125706 C7.93386128,0.190125706 7.48614603,0.637840957 7.48614603,1.19012571 Z'
                        id='Line-2'
                      ></path>
                      <path
                        d='M7.71948463,1.53540566 L7.71948463,17.6852209 C7.71948463,18.2375056 8.16719988,18.6852209 8.71948463,18.6852209 C9.27176938,18.6852209 9.71948463,18.2375056 9.71948463,17.6852209 L9.71948463,1.53540566 C9.71948463,0.983120914 9.27176938,0.535405664 8.71948463,0.535405664 C8.16719988,0.535405664 7.71948463,0.983120914 7.71948463,1.53540566 Z'
                        id='Line-2'
                        transform='translate(8.719485, 9.610313) rotate(-90.000000) translate(-8.719485, -9.610313) '
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

CookieConsent.contextType = PageContext;

export default CookieConsent;
