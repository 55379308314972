import countries from "./country-list";
export function marketoSetCountries(ctx) {
  // console.log(ctx);

  const cs = [...document.querySelectorAll('[name=Country]')];
  cs.map(c => {
    c.value = (typeof countries[ctx.geolocation] !== 'undefined') ? countries[ctx.geolocation] : 'NA';
    c.style.display = 'none';
  });

  const countryLabels = [...document.querySelectorAll('[id=LblCountry]')];
  countryLabels.map(c => {
    c.classList.remove('mktoLabel');
    c.style.display = 'none';
  });

}
