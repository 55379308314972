/**
 * Replace whitespace in a search query with "+" characters.
 *
 * WP Engine returns a `400` error for users not signed in to WordPress if a URL's
 * path contains a space, including URL-encoded spaces.
 *
 * @param {string} query - The query to sanitise
 *
 * @returns {string} A sanitised version of the query
 */
 export function sanitiseQuery(query: string): string {
	return query.replace(/\s+/g, '+');
}
