import React, { Component } from 'react';
import axios from 'axios';
import { MDCSelect } from '@material/select';
import { MDCTextField } from '@material/textfield';
import * as Sentry from '@sentry/browser';

import { gdprSet } from '../utils/gdpr';
import countries from '../utils/country-list';
import { marketoSetCountries } from '../utils/marketo-set-countries';
import { PageContext } from '../context/page-context';

import BookACallCarousel from './book-a-call/carousel';
const ID = 1118;
const categories = {
    1: 'Everything else!',
    2: 'Hair',
    3: 'Beauty',
    4: 'Massage/Day Spa',
    6: 'Health/Wellbeing Clinic',
    7: 'Tutoring/Consulting',
    12: 'Hair & Beauty',
    13: 'Barber',
    14: 'Body Art',
    15: 'Personal Training',
};

// const keys = ['13', '3', '14', '1', '2', '12', '6', '4', '15', '7'];

// const times = [
//     { value: '00', label: '12am' },
//     { value: '01', label: '1am' },
//     { value: '02', label: '2am' },
//     { value: '03', label: '3am' },
//     { value: '04', label: '4am' },
//     { value: '05', label: '5am' },
//     { value: '06', label: '6am' },
//     { value: '07', label: '7am' },
//     { value: '08', label: '8am' },
//     { value: '09', label: '9am' },
//     { value: '10', label: '10am' },
//     { value: '11', label: '11am' },
//     { value: '12', label: '12pm' },
//     { value: '13', label: '1pm' },
//     { value: '14', label: '2pm' },
//     { value: '15', label: '3pm' },
//     { value: '16', label: '4pm' },
//     { value: '17', label: '5pm' },
//     { value: '18', label: '6pm' },
//     { value: '19', label: '7pm' },
//     { value: '20', label: '8pm' },
//     { value: '21', label: '9pm' },
//     { value: '22', label: '10pm' },
//     { value: '23', label: '11pm' },
// ];

class BookACall extends Component {
    state = {
        form: {
            fname: '',
            lname: '',
            email: '',
            tel: '',
            industry: '',
            preferredTime: '',
            geolocation: '',
        },
        formSubmitted: false,
    };

    fields = [];

    formFields = [
        'fname',
        'lname',
        'tel',
        'email',
        'industry',
        'timely-user',
        'preferredTime',
        'geolocation',
    ];

    UNSAFE_componentWillMount() {
        this.getPersonalInfo();
        document.querySelector("body").classList.remove("Overlay-on");

        const { MktoForms2 } = window;
        if (typeof MktoForms2 !== 'undefined') {
            MktoForms2.loadForm("//go.gettimely.com", "304-RPU-875", ID, (form) => {
                marketoSetCountries(this.context);
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { form } = this.state;

        const newForm = Object.assign({}, form, { [name]: value });

        this.setState({
            form: newForm,
        });
    }

    componentDidUpdate() {
        gdprSet();

        const selectElements = [].slice.call(
            document.querySelectorAll('.mdc-select')
        );

        selectElements.forEach(selectEl => {
            // new MDCSelect(selectEl);
            const input = selectEl.querySelectorAll('select');

            if (input[0]) {
                this.fields[input[0].name] = new MDCSelect(selectEl);
            }
        });

        const textFieldElements = [].slice.call(
            document.querySelectorAll('.mdc-text-field')
        );

        textFieldElements.forEach(textFieldEl => {
            const input = textFieldEl.querySelectorAll('input');
            if (input[0]) {
                this.fields[input[0].name] = new MDCTextField(textFieldEl);
            }
        });

        setTimeout(() => {
            this.formFields.forEach(field => {
                if (this.fields[field]) {
                    this.fields[field].layout();
                }
            });
            gdprSet();

        }, 450);
    }

    handleInputChange = event => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { form } = this.state;

        const newForm = Object.assign({}, form, { [name]: value });

        this.setState({
            form: newForm,
        });
    };

    getPersonalInfo() {
        const { form } = this.state;
        let { context } = this.props;

        setTimeout(() => {
            gdprSet();
        }, 450);

        if (!context) {
            context = this.context;
        }
        const newForm = Object.assign({}, form);

        this.formFields.forEach(field => {
            if (context[field]) {
                newForm[field] = context[field];
            }
        });

        this.setState({
            form: newForm,
        });

        setTimeout(() => {
            const selectElements = [].slice.call(
                document.querySelectorAll('.mdc-select')
            );

            selectElements.forEach(selectEl => {
                // new MDCSelect(selectEl);
                const input = selectEl.querySelectorAll('select');

                if (input[0]) {
                    this.fields[input[0].name] = new MDCSelect(selectEl);
                }
            });

            const textFieldElements = [].slice.call(
                document.querySelectorAll('.mdc-text-field')
            );

            textFieldElements.forEach(textFieldEl => {
                const input = textFieldEl.querySelectorAll('input');
                if (input[0]) {
                    this.fields[input[0].name] = new MDCTextField(textFieldEl);
                }
            });
        }, 250);
    }

    generateAutopilotData(form) {
        const form_data = new FormData();
        let sessionId = '';
        const AutopilotAnywhere = window.AutopilotAnywhere || false;

        let MailingCountry = 'United Kingdom';
        if (typeof countries[form.geolocation] !== 'undefined') {
            MailingCountry = countries[form.geolocation];
        }

        // TODO Test marketing GDPR
        let newsletter = false;
        if (document.querySelector('html').classList.contains('nogdpr')) {
            newsletter = 'yes';
        } else {
            newsletter = form.newsoffers == true ? 'yes' : 'no';
        }

        if (form.preferredTime) {
            var offset = 24 - new Date().getTimezoneOffset() / 60;
            var utcTime = parseInt(form.preferredTime) + offset;
            var perfTime = form.preferredTime;

            utcTime %= 24;
            utcTime = utcTime.toString();
            if (utcTime.length === 1) { utcTime = '0' + utcTime + ':00'; }
            if (utcTime.length === 2) { utcTime = utcTime + ':00'; }
            if (utcTime.length === 3) { utcTime = utcTime[0] + utcTime[1] + ':30'; }
            form.time_utc = utcTime;

            var gmt = (new Date().getTimezoneOffset() / 60) * -1;

            if (gmt >= 0) { gmt = '+' + gmt; }
            form.offset = gmt;
            form.time = `${form.preferredTime}:00`;
        }

        if (AutopilotAnywhere && AutopilotAnywhere.sessionId) {
            sessionId = AutopilotAnywhere.sessionId;
        }

        let BusinessCategory = categories[form.industry];
        BusinessCategory = BusinessCategory.replace('&', 'and');


        form_data.append('action', 'submit_to_autopilot');
        form_data.append(
            'data',
            JSON.stringify({
                _autopilot_session_id: sessionId,
                contact: {
                    Email: form.email,
                    FirstName: form.fname,
                    LastName: form.lname,
                    Phone: form.tel,
                    MailingCountry,
                    custom: {
                        'string--BusinessCategory': BusinessCategory,
                        'string--Source_LatestMainSource': 'Contact Request',
                        'string--Source_LatestSubSource': 'Call Request',
                        'string--Source_LatestSourceDetail':
                            'Request a Callback',
                        'string--FormField_Privacy': 'yes',
                        'string--FormField_Newsletter': newsletter,
                        'string--DEMOTIMEZONE': form.offset,
                        'string--DEMOTIMEUTC': form.time_utc,
                        'string--PREFERREDCALLTIME': perfTime,
                        'string--DEMOTIME': form.time,
                        'string--DEMOURL': window.location.pathname,
                    },
                },
            })
        );

        return form_data;
    }

    submitAutopilotData(autopilotData) {
        const {
            timber_ajax_obj,
        } = window;
        if (typeof timber_ajax_obj !== 'undefined') {
            axios
                .post(timber_ajax_obj.ajaxurl, autopilotData)
                .then(res => { })
                .catch(err => {
                    Sentry.captureMessage(`Submit book a call, ${err}`);
                });
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        let { context } = this.props;

        if (!context) {
            context = this.context;
        }
        const { setLocalStorage } = context;
        const { form } = this.state;

        if (typeof setLocalStorage === 'function') {
            setLocalStorage(form);
        }

        const formData = this.generateAutopilotData(form);
        this.submitAutopilotData(formData);
        this.setState({ formSubmitted: true });
    };

    renderTextField(label, id) {
        const { form } = this.state;
        // const labelClass =
        //     form[id] && form[id].length > 0
        //         ? 'mdc-floating-label mdc-floating-label--float-above'
        //         : 'mdc-floating-label';

        return (
            <div>
                <div className="mdc-text-field mdc-text-field--outlined mdc-text-field--fullWidth ">
                    <input
                        onChange={this.handleInputChange}
                        name={id}
                        defaultValue={form[id]}
                        className="mdc-text-field__input"
                        required
                    />
                    <div className="mdc-notched-outline">
                        <div className="mdc-notched-outline__leading"></div>
                        <div className="mdc-notched-outline__notch">
                            <label className="mdc-floating-label">
                                {label}
                            </label>
                        </div>
                        <div className="mdc-notched-outline__trailing"></div>
                    </div>
                </div>
                <div className="mdc-text-field-helper-line">
                    <p className="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
                        This field is required
                    </p>
                </div>
            </div>
        );
    }

    renderSuccess() {
        const { successMessage } = this.props.block.content;
        return <div dangerouslySetInnerHTML={{ __html: successMessage }} />;
    }

    renderForm() {
        return (
            <div className="form-labels-dark">
                <p>&nbsp;</p>
                <h2>Request a call</h2>
                <p className="u-mb4">We can chat about your business, answer your questions and show you how Timely can help. Select your preferred call time and we’ll do our best to call you then.</p>
                <form id={`mktoForm_${ID}`}></form>
            </div>
        );
    }

    render() {
        const { formSubmitted } = this.state;
        const carousel = this.props.block || {
            content: {
                bgImg:
                    '/wp-content/uploads/2019/10/desktop-homepage-hero-still.jpg',
                items: [
                    {
                        quote:
                            '“The Timely Team were able to provide answers to all of my questions, find solutions to my problems”',
                        customer: 'Leah Miller',
                        business: 'Waxed, Melbourne',
                    },
                    {
                        quote:
                            '“The Timely Team were able to provide answers to all of my questions, find solutions to my problems”',
                        customer: 'Leah Miller',
                        business: 'Waxed, Melbourne',
                    },
                    {
                        quote:
                            '“The Timely Team were able to provide answers to all of my questions, find solutions to my problems”',
                        customer: 'Leah Miller',
                        business: 'Waxed, Melbourne',
                    },
                    {
                        quote:
                            '“The Timely Team were able to provide answers to all of my questions, find solutions to my problems”',
                        customer: 'Leah Miller',
                        business: 'Waxed, Melbourne',
                    },
                ],
            },
        };

        return (
            <div className="BookACall-wrap">
                <div className="BookACall-form">
                    <div className="BookACall-form-container u-textCenter">
                        {!formSubmitted && this.renderForm()}
                        {formSubmitted && this.renderSuccess()}
                    </div>
                </div>
                <BookACallCarousel block={carousel} />
            </div>
        );
    }
}

BookACall.contextType = PageContext;

export default BookACall;
