import type { MarketoForm } from './marketo';

const enum MarketoFormFields {
    MOBILE_PHONE = 'MobilePhone',
}

/** A function for running a custom validation rule. Returns `true` if the rule passes, otherwise returns a `MarketoCustomValidationError`. */
type MarketoCustomValidationFn = (values: ReturnType<MarketoForm['getValues']>) => true | MarketoCustomValidationError;

type MarketoCustomValidationError = {
    message: string,
    fieldName: string,
};

type MarketoCustomValidationSummary = {
    valid: boolean,
    errors: MarketoCustomValidationError[],
};

/**
 * A set of custom validation functions that will be iterated through after a Marketo form with custom validation added has passed its initial validation.
 */
const customValidators: Record<string, MarketoCustomValidationFn> = {
    mobilePhone: (values) => {
        const countryCodePattern = /^\+[1-9]/;

        const mobilePhoneValue = values[MarketoFormFields.MOBILE_PHONE];
        if (typeof mobilePhoneValue === 'undefined') {
            // This form doesn't have a mobile phone field, so it can't be invalid
            return true;
        }

        const mobilePhoneValid = countryCodePattern.test(mobilePhoneValue);

        if (mobilePhoneValid) {
            return true;
        } else {
            return {
                message: 'Please start your mobile number with the country code <strong>+xx</strong>',
                fieldName: MarketoFormFields.MOBILE_PHONE,
            };
        }
    },
};

/**
 * Iterate through each custom validation rule, and return a validation summary.
 */
function validateMarketoForm(form: MarketoForm): MarketoCustomValidationSummary {
    const validationSummary: MarketoCustomValidationSummary = {
        valid: true,
        errors: [],
    };

    const values = form.getValues();
    Object.entries(customValidators).forEach(([ruleName, ruleFn]) => {
        if (validationSummary.valid) {
            const ruleResult = ruleFn(values);
            if (ruleResult !== true) {
                validationSummary.valid = false;
                validationSummary.errors.push(ruleResult);
            }
        }
    });

    return validationSummary;
}

/**
 * Show a specific error related to a particular field on a particular Marketo form.
 *
 * Known issue: The method provided by Marketo for showing an error message doesn't mark a field as invalid.
 * We can't easily hack around this since Marketo re-marks fields as valid when they receive focus.
 */
function showValidationError(form: MarketoForm, error: MarketoCustomValidationError): void {
    const formElem = form.getFormElem();
    const fieldElem = formElem?.find(`[name="${error.fieldName}"]`);

    if (fieldElem && fieldElem.length > 0) {
        // Marketo's internal code doesn't correctly handle JQuery objects with length 0
        form.showErrorMessage(error.message, fieldElem);
    } else {
        form.showErrorMessage(error.message);
    }
}

/** This `WeakSet` is used to keep track of which forms have had custom validation turned on, to prevent duplicate event bindings. */
const formsWithCountryCodeValidation: WeakSet<MarketoForm> = new WeakSet();

/**
 * Enable custom validation rules on a Marketo form. Custom validation rules are checked after Marketo's base validation rules.
 * If any custom validation rules fail, the form won't be able to be submitted and an error will be displayed for the failed rule.
 *
 * This function can safely be called multiple times without binding additional event listeners.
 */
export function addCustomValidationRules(): void {
    const { MktoForms2 } = window;
    if (MktoForms2) {
        MktoForms2.whenReady((form) => {
            if (formsWithCountryCodeValidation.has(form)) {
                return;
            }
            formsWithCountryCodeValidation.add(form);

            form.onValidate((valid) => {
                if (valid) {
                    const validationSummary = validateMarketoForm(form);

                    if (validationSummary.valid === true) {
                        form.submittable(true);
                    } else {
                        form.submittable(false);
                        validationSummary.errors.forEach((error) => showValidationError(form, error));
                    }
                }
            });
        });
    }
}
