import { Region } from '../Region';

export const PricingRegion = {
	AUSTRALIA: Region.AUSTRALIA,
	CANADA: Region.CANADA,
	GREAT_BRITAIN: Region.GREAT_BRITAIN,
	IRELAND: Region.IRELAND,
	NEW_ZEALAND: Region.NEW_ZEALAND,
	UNITED_STATES: Region.UNITED_STATES,
	REST_OF_THE_WORLD: Region.REST_OF_THE_WORLD,
};
export type PricingRegion = typeof PricingRegion[keyof typeof PricingRegion];

const validPricingRegions = Object.values(PricingRegion);
export const isPricingRegion = (value: unknown): value is PricingRegion => {
	return (validPricingRegions as unknown[]).includes(value);
};
