import getPrices, { PricingMatrixEntry, getSmsPricing } from './pricing-matrix';
import { TimelyPlanName } from './TimelyPlanName';
import { PricingCalculationMode } from './PricingCalculationMode';
import { PricingRegion } from './PricingRegion';
import { SmsPricingRegion } from './SmsPricingRegion';

const pricingMatrix = getPrices();
const pricingRegions = Object.values(PricingRegion);

const smsPricing = getSmsPricing();
const smsPricingRegions = Object.values(SmsPricingRegion);

/**
 * Checks if a given string is a valid pricing region.
 */
function isPricingRegion(region: string): region is PricingRegion {
	return (pricingRegions as unknown[]).includes(region);
}

/**
 * Retreives the prices for a given region.
 *
 * Defaults to "Rest of the world" if an unrecognised region is passed.
 *
 * @param {string} region - The country code for the customer's region
 */
export function getRegionPriceInfo(region: string): PricingMatrixEntry {
	if (isPricingRegion(region)) {
		return pricingMatrix[region];
	} else {
		return pricingMatrix[PricingRegion.REST_OF_THE_WORLD];
	}
}

/**
 * Retrieves the monthly solo price for a given region and plan.
 *
 * @param {string} region - The country code for the customer's region
 * @param {TimelyPlanName} plan - The name of the plan
 */
export function getSoloMonthlyPrice(region: string, plan: TimelyPlanName): number {
	const regionPrices = getRegionPriceInfo(region);
	const soloPrice = regionPrices.prices.solo[plan];

	return soloPrice;
}

/**
 * Retrieves the monthly per-staff price for a given region and plan.
 *
 * @param {string} region - The country code for the customer's region
 * @param {TimelyPlanName} plan - The name of the plan
 * @param {number} [staffCount=1] - The number of staff the plan is for. Defaults to 1
 */
function getPerStaffMonthlyPrice(region: string, plan: TimelyPlanName, staffCount: number = 1): number {
	const regionPrices = getRegionPriceInfo(region);
	const { calculationMode } = regionPrices.prices;

	let totalPrice: number | null = null;
	switch (calculationMode) {
		case PricingCalculationMode.SOLO_PLUS_STAFF_MULTIPLE:
			totalPrice = (() => {
				const soloPrice = getSoloMonthlyPrice(region, plan);

				const extraStaffPriceRate = regionPrices.prices.staff[plan];
				const extraStaffPrice = extraStaffPriceRate * (staffCount-1);

				const totalPrice = soloPrice + extraStaffPrice;
				return totalPrice;
			})();
			break;
		case PricingCalculationMode.STAFF_MULTIPLE:
		default:
			totalPrice = (() => {
				const staffPrice = regionPrices.prices.staff[plan];

				const totalPrice = staffPrice * staffCount;
				return totalPrice;
			})();
			break;
	}

	return totalPrice;
}

/**
 * Retrieves the monthly fixed price for a given region and plan.
 *
 * If a plan doesn't have a fixed price, returns `null`.
 *
 * @param {string} region - The country code for the customer's region
 * @param {TimelyPlanName} plan - The name of the plan
 */
export function getFixedMonthlyPrice(region: string, plan: TimelyPlanName): number | null {
	const regionPrices = getRegionPriceInfo(region);

	if (plan in regionPrices.prices.fixed) {
		const fixedPrice = regionPrices.prices.fixed[plan];

		return fixedPrice ?? null;
	} else {
		return null;
	}
}

/**
 * Retrieves the monthly price for a given region and plan at a specified number of staff.
 *
 * If the plan doesn't have a price offering for the specified number of staff, returns `null`.
 *
 * @param {string} region - The country code for the customer's region
 * @param {TimelyPlanName} plan - The name of the plan
 * @param {number} [staffCount=1] - The number of staff the plan is for. Defaults to 1
 */
export function getPlanMonthlyPrice(region: string, plan: TimelyPlanName, staffCount: string | number = 1): number | null {
	if (typeof staffCount === 'string') {
		staffCount = parseInt(staffCount, 10);
	}

	if (staffCount === 1) {
		return getSoloMonthlyPrice(region, plan);
	} else if (staffCount < 8) {
		return getPerStaffMonthlyPrice(region, plan, staffCount);
	} else if (staffCount < 20) {
		return getFixedMonthlyPrice(region, plan);
	} else {
		return null;
	}
}

/**
 * Retrieves a display string for the monthly price for a given region
 * and plan at a specified number of staff.
 *
 * If the plan doesn't have a price offering for the specified number of staff, returns `null`.
 *
 * @param {string} region - The country code for the customer's region
 * @param {TimelyPlanName} plan - The name of the plan
 * @param {number} [staffCount=1] - The number of staff the plan is for. Defaults to 1
 */
export function getPlanMonthlyPriceDisplay(region: string, plan: TimelyPlanName, staffCount: string | number = 1): string | null {
	const price = getPlanMonthlyPrice(region, plan, staffCount);
	if (price === null) {
		return null;
	}

	const priceInfo = getRegionPriceInfo(region);

	const formatter = new Intl.NumberFormat('en-NZ', {
		style: 'currency',
		currencyDisplay: 'narrowSymbol',
		currency: priceInfo.currencyCode,
		maximumFractionDigits: 0,
	});

	return formatter.format(price);
}

/**
 * Retrieves the annual price for a given region and plan at a specified number of staff.
 *
 * If the plan doesn't have a price offering for the specified number of staff, returns `null`.
 *
 * @deprecated We no longer offer annual plans
 *
 * @param {string} region - The country code for the customer's region
 * @param {TimelyPlanName} plan - The name of the plan
 * @param {number} [staffCount=1] - The number of staff the plan is for. Defaults to 1
 */
export function getPlanAnnualPrice(region: string, plan: TimelyPlanName, staffCount?: number): number | null
/**
 *
 * Retrieves the annual price for a given region and plan at a specified number of staff.
 *
 * If the plan doesn't have a price offering for the specified number of staff, returns `null`.
 *
 * @deprecated We no longer offer annual plans
 *
 * @param {number} monthlyPrice - The comparable annual price for a given monthly price
 */
export function getPlanAnnualPrice(monthlyPrice: number): number
export function getPlanAnnualPrice(regionOrMonthlyPrice: string | number, plan?: TimelyPlanName, staffCount: number = 1): number | null {
	let monthlyPrice: number | null = null;

	if (typeof regionOrMonthlyPrice === 'string') {
		if (typeof plan === 'undefined') {
			throw new Error(`ERROR: Invalid arguments passed to \`getPlanAnnualPrice\``);
		}

		monthlyPrice = getPlanMonthlyPrice(regionOrMonthlyPrice, plan, staffCount);
	} else {
		monthlyPrice = regionOrMonthlyPrice;
	}

	if (monthlyPrice === null) {
		return null;
	}

	const annualPrice = monthlyPrice * 10;
	return annualPrice;
}

/////////
// SMS //
/////////
/**
 * Checks if a given string is a valid pricing region.
 */
function isSmsPricingRegion(region: string): region is SmsPricingRegion {
	return (smsPricingRegions as unknown[]).includes(region);
}

/**
 * Determines how many free SMS messages per month are allowed for a given region, plan, and and number of staff.
 */
export function getFreeSmsPerMonth(
	region: string,
	plan: Exclude<TimelyPlanName, TimelyPlanName.BASIC>,
	staffCount = 1
): number {
	const smsRegion = isSmsPricingRegion(region) ? region : SmsPricingRegion.REST_OF_THE_WORLD;
	const smsPricingInfo = smsPricing[smsRegion];
	return smsPricingInfo.freeSmsPerStaffPerMonth[plan] * staffCount;
}

